import { FormattedSKU } from '@talentinc/gatsby-theme-ecom/hooks/useSKUs'
import { TR, TCV, TCVUK } from './packages/packages'

export type Segment =
  | 'blue_collar'
  | 'academic'
  | 'white_collar_0_5'
  | 'white_collar_6_10'
  | 'white_collar_11'
  | 'unknown'

export type PricePoint = {
  plan_code: string
  sku: FormattedSKU
  copy: {
    description: string
    selling_points: string[]
    hover: {
      phrase: string
      items: ({ title: string; description: string } | null)[]
    }
  }
}

export function getPackagesBySiteID(siteID?: number) {
  switch (siteID) {
    case 1: // TopResume
      return TR
    case 126: // TopCV.co.uk
      return TCVUK
    case 127: // TopCV
      return TCV
    default:
      throw new Error('Unknown siteID ' + siteID)
  }
}
