import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import { FormattedSKU, useAllSKUs } from '@talentinc/gatsby-theme-ecom/hooks/useSKUs'
import { COPY } from './packages/packages-copy'
import { COPY_HOVER } from './packages/packages-copy-hover'
import { Segment, PricePoint, getPackagesBySiteID } from './usePricePoints.utils'
import { isSSR } from '../../../utils/env'

type Input = {
  segment?: string
  type: 'Job Search' | 'Resume Writing'
}

type Output = {
  match: Segment
  pricePoints: PricePoint[]
}

export const usePricePoints = (data: Input): Output => {
  const skus = useAllSKUs()
  const { site_id } = useBrand()
  const packages = getPackagesBySiteID(site_id)

  /**
   * NOTE: Break build if unable to find SKU for all the plan codes
   -------------------------------------------------------------------------*/
  if (isSSR) {
    const skusPlanCodes = skus.map((i) => i.plan_code)
    const errors = []

    for (const p of Object.values(packages).flat()) {
      if (!skusPlanCodes.includes(p.plan_code)) {
        errors.push('Unable to find SKU for plan_code ' + p.plan_code)
      }
    }

    if (errors.length > 0) {
      throw new Error(errors.join('\n'))
    }
  }

  const packagesInSegment = packages[data.type]?.filter(
    (i) => i.segment === data.segment
  )

  const package2pricepoint = (p: { plan_code: string; copy: number }) => {
    const sku = skus.find((j) => j.plan_code === p.plan_code) as FormattedSKU

    const copy = {
      ...COPY[p.copy],
      hover: {
        phrase: COPY[p.copy].hover.phrase,
        items: COPY[p.copy].hover.items.map((index) => COPY_HOVER[index]),
      },
    }

    return { ...p, sku, copy }
  }

  if (packagesInSegment.length <= 0) {
    return {
      match: 'unknown',
      pricePoints:
        packages[data.type]
          ?.filter((i) => (i.segment as Segment) === 'unknown')
          ?.map(package2pricepoint) ?? [],
    }
  }

  return {
    match: data.segment as Segment,
    pricePoints: packagesInSegment.map(package2pricepoint),
  }
}
