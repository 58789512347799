import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles<void, 'content' | 'img' | 'title_wraper'>()(
  (theme, _, classes) => ({
    root: {
      display: 'flex',
      margin: '55x 0 32px -24px',
      paddingTop: '5px',
      paddingBottom: '5px',
      backgroundImage:
        'url(/static/herosectionnewpagebg-b9694ff313c92dba983f071dba455c82.png)',
      backgroundSize: '80% 95%',
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',

      [theme.breakpoints.down('lg')]: {
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
      },

      [theme.breakpoints.down('md')]: {
        margin: '32px 0 0 0',
        paddingLeft: 0,
        paddingRight: 0,

        [`& .${classes.content}`]: {
          padding: '24px 0 0 0',
          alignItems: 'center',
          textAlign: 'center',
          margin: 'auto',

          '& h2': {
            fontSize: '17px',
          },
          '& h1': {
            fontSize: '2.64em',
          },
        },

        [`& .${classes.title_wraper}`]: {
          alignItems: 'center',
        },

        [`& .${classes.img}, & button`]: {
          display: 'none',
        },
      },

      [theme.breakpoints.up('lg')]: {
        [`& .${classes.title_wraper}`]: {
          maxWidth: '80%',
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: '24px 0 0 24px',
      gap: '18px',
    },
    title_wraper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    title: {
      margin: '0px !important',
    },
    subtitle1: {
      margin: '0px !important',
      color: '#5A6377',
      fontSize: '20px',
      textTransform: 'capitalize',
    },
    subtitle2: {
      margin: '0px !important',
      fontSize: '20px',
      fontFamily: 'proxima-nova,Helvetica,Arial,sans-serif',
      fontWeight: 400,
      lineHeight: '28px',
      color: '#202124',
      textTransform: 'none',
    },
    img: {
      padding: '32px 0 0 24px',

      '& > img': {
        width: '362px',
        height: '295px',
      },
    },
  })
)
